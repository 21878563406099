import React, { Component } from 'react'
import VideoIdMain from './video'
export default class VideoIdcapture extends Component {
    render() {
        return (
            <div>
                <VideoIdMain />
            </div>
        )
    }
}
