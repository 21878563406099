import React, { Component } from 'react'
// https://www.youtube.com/watch?v=9bfOe0qsmAY
// https://youtu.be/9bfOe0qsmAY
import YouTube from 'react-youtube';
const StoreVideoOnPlayFormat=[];
const TotalTimeUser=[];
export default class video extends Component {
   
  
  startfunctin=(evt)=>
    {
        console.log("asdsad", evt)
    }

VideoOnPlay(event) {
let videoPlayedAt=event.target.getCurrentTime()
var hours = new Date().getHours();
var hourss = (hours+24-2)%24; 
var mid='am';
if(hourss==0){ //At 00 hours we need to show 12 am
hours=12;
}
else if(hourss>12)
{
hours=hours%12;
mid='pm';
}
// alert ('Toronto time: ' + hours +);
let VideoOnPlayToday = new Date();
// var date = VideoOnPlayToday.getFullYear()+'-'+(VideoOnPlayToday.getMonth()+1)+'-'+VideoOnPlayToday.getDate();
let time = VideoOnPlayToday.getHours() + ":" + VideoOnPlayToday.getMinutes() + ":" + VideoOnPlayToday.getSeconds();
let TodayTime = time + mid;

TotalTimeUser.push(TodayTime)
    if (videoPlayedAt > 0) {
    

      let hours= Math.floor(videoPlayedAt/3600);
      videoPlayedAt %= 3600;
      let mins = Math.floor(videoPlayedAt/60);
       videoPlayedAt %= 60;
      let  VideoOnPlayFormat = ( (hours < 10 ) ? "0" : "" ) + hours + ":" + ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (videoPlayedAt < 10) ? "0" : "" ) + videoPlayedAt;
    StoreVideoOnPlayFormat.push(VideoOnPlayFormat)
      console.log("User_Started_Play_video_At",StoreVideoOnPlayFormat)

      }
      console.log("Time_AT_User_played_video",TotalTimeUser)
    
}

    VideoOnReady(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
        // console.log("video pused",event.target.pauseVideo())
      }
    
    render() {

        const opts = {
            // height: '390',
            // width: '640',
            playerVars: { // https://developers.google.com/youtube/player_parameters
              autoplay: false
            }
          };


        return (
            <div>
                  <YouTube
        videoId='9bfOe0qsmAY'
        opts={opts}
        onReady={this.VideoOnReady}
        onPlay={this.VideoOnPlay}
      /> 
            </div>
        )
    }
}