import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './Component/Home/Home'
import Fp2 from './Component/Home/Fp2'
import Fp3 from './Component/Home/Fp3'
import './App.scss'
import Landing from './Page/Landing'
import videoVideoIdMainfile from './Component/VideoIdcapture'

// window.addEventListener("beforeunload", (ev) => 
// {  
//     ev.preventDefault();
//     return ev.returnValue = 'Are you sure you want to close?';
// });
export default class App extends Component {
  render() {
    return (
      <>
      <div>
      
         <>
        <Router>
           <Switch>
          <Route path="/" exact component={Landing}/> 
    
         </Switch>
        </Router>
        </>
      
      </div>
      </>
    )
  }
}


