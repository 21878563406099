import React, { Component } from 'react'
import axios from 'axios';
import SubHome from '../Home/SubHome';
import {ProductConsumer} from '../../ContextApi'
import { Beforeunload } from 'react-beforeunload';
let pk
export default class Home extends Component {
  static contextType = ProductConsumer

  componentDidMount= async ()=>      
  {
  
    window.onbeforeunload = async (evt)=> {
     evt.preventDefault()
     await this.context.Leavesubmitedata()
 
      var message = 'Are you sure you want toleave?';
      if (typeof evt == 'undefined') {
       evt.returnValue =" message"

      }       
      if (!evt) {     
    
   
      evt.returnValue =" message";
   
      }
      else
      {
        // await this.context.Leavesubmitedata();
      }
      console.log("bubbles",evt.bubbles)
      console.log("cancelable",evt.cancelable)
      console.log("evt",evt)
 
       return message;
    
    
  } 
  window.onunload = async()=> {

    await this.context.Leavesubmitedata()
  

  }
  
    // window.addEventListener("beforeunload", (ev) => 
    // {    this.context.sendData()
    //     ev.preventDefault();
      
        // console.log("kasdmasdasdasdasdalsdlasd",window.location.pathname + window.location.search);
        // return 'You have unsaved chaaasnges!';

//  console.log("kasdmasdasdasdasdalsdlasd",window.location.pathname + window.location.search);
//  this.context.updatePathName(window.location.pathname + window.location.search)


  }
  
    async componentWillMount(){
        let aff = await axios.post("https://comms.globalxchange.com/get_affiliate_data_no_logs", {
          affiliate_id: this.props.match.params.affiliate_id
        });
    
        if (aff.data.length === 0) {
          alert(
            "The Affiliate-id is not recognized by the Global Xchange portal. Please replace the link with the appropriate affiliate-id in it...!  "
          );
          window.location.replace("/1");
        } else {
          localStorage.setItem("affiliate_id", this.props.match.params.affiliate_id);
          this.context.SendAllLandingData()
          console.log(aff.data[0].name);
        }
      }

      myfun=()=>{
        // Write your business logic here
        console.log('hello');
    }
  render() {
     
    return (
      <ProductConsumer>
        {context_data => {
          const {
            affiliate_id,
            name,
            EmailFunction

          } = context_data;

      
          return (
            <>
              <SubHome/>
            </>
               
          );
        }}
      </ProductConsumer>
    );
  }
}



