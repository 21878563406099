import React, { Component } from 'react'
import axios from "axios";
import uuid from "uuid/v4"

let timeInSecs;
let temp = 1;
let secs;
let mins;
let pageonetime;
let pretty
let infopath = []
const StoreVideoOnPlayFormat = [];
const StoreVideoOnPlayPused = []
const VideoOnPlayPused = [];
const StoreVideoOnPlayPusedTwo = [];
const StoreVideoOnPlayFormatTwo = [];
const VideoOnPlayPusedSecond=[];
const TotalTimeUser = [];
const VideoProgress = [];
const VideoSeek = [];
const VideoBuffer = []
const VideoBufferSecond=[]
const VideoSeekSecond=[]
const publicIp = require('public-ip');


const ProductContext = React.createContext();
const fetch = {url: 'http://localhost:7000/'}
class ProductProvider extends Component {
  state = {
    show: false,
    videostartplaing:false,
    playingSecond:false,
    formlength: 1,
    name: "",
    email: "",
    campaignname: "c1",
    affiliate_id: "affiliate_id",
    username: "",
    next: false,
    UserStayedOnHomepageTime: "",
    stopPageOneTimmer: true,
    webinarButton: "Not Click",
    pathname: [],
    storetotalTime: [],
    seekvideo:false,
    UserID: uuid(),
    TodayPageTime: "",
    TodayPagedate: "",
    UserIpAddress: "",
    Datef2Page: "",
    userNameInput: "Not Click",
    usernameType: false,
    usernameEmailInput: ": NOt Click",
    useremailType: false,
    popupClose: "",
    Userlocation: "",
    storevideplayed: [],
    storeVidePused: [],
    storepused: [],
    storepusedSecond:[],
    storeVidePusedTwo: [],
    storevideplayedTwo: [],
    formsubmit: "Not Submited And Close Model OR Left Page ",
    TodayPagedateTwo: "",
    TodayPageTimeTwo: "",
    storeBufferSecond:[],
    Buttonclick2: "Not Click",
    Buttonclick3: "Not Click",
    valid: true,
    playing: false,
    played: 0,
    storeprogress: [],
    storeseek: [0],
    storeBuffer: [],
    UserSelected: [],
    UserIsp:"",
    device:"",
    status:"Engaged",
    form_length: 1,
    UserSelectedEmail:[],
    UDate:"",
    storeseekSecond:[],
    storeprogressSecond:[],
    timestamp: Date.now()
  }

  handleOnReadys = () => setTimeout(() => this.setState({ playing: true }), 100);
  handlePlay = async () => {

    await this.setState({ 
    playing: true,
    videostartplaing:true,
   }
   
      
   )
   if(this.state.playing===true)
   {
    await this.setState({ 
      videostartplaing:true,
     })

   }
   else
   {
    await this.setState({ 
      videostartplaing:false,
     })
   }
  }
  SendAllLandingData= async()=>
  {

    let affiliate_id = localStorage.getItem("affiliate_id");
    let user_id=this.state.UserID;
    localStorage.setItem("user_id", user_id)
  // Fetching LOcation
  try {
    let resp = await axios.get('https://ipapi.co/'+this.state.UserIpAddress+'/city')
    let location=resp.data
    this.setState({
      Userlocation:location
    })
  } catch(err){
    console.log("asdasdasdasdads",err)
  }
  try {
    let resp = await axios.get('https://ipapi.co/'+this.state.UserIpAddress+'/org')
    let Isp=resp.data
    this.setState({
      UserIsp:Isp
    })
  } catch(err){
    console.log("asdasdasdasdads",err)
  }

  // Deivice 
if(window.innerWidth < 768){
    // Extra Small Device
   await this.setState(
      {
        device:"Moible"
      }
    )
   
     
} else if(window.innerWidth < 991){
    // Small Device
    await this.setState(
      {
        device:"tab"
      }
    )
       } 
       
       else if(window.innerWidth < 1199){
    // Medium Device
    await this.setState(
      {
        device:"Desktop or laptop"
      }
    )
       } else {
        await this.setState(
          {
            device:"Desktop or laptop"
          }
        )
       }
   
     let date = new Date();
     date = date.toLocaleString("en-US", {timeZone: "America/New_York"});
     await this.setState(
      {
        UDate: date}
  )
     // fetch Ip Address
     let po = await (publicIp.v4());
      await this.setState(
       {
         UserIpAddress: po}
   )



  let res =  await axios.post("https://comms.globalxchange.com/campaign/insertlead", {
    user_id: user_id,
    campaign: this.state.campaignname,
    affiliate_id: affiliate_id,
    username: affiliate_id,
     Timestamp:this.state.timestamp,
     Date:date,
    ip_address: this.state.UserIpAddress,
    location: this.state.Userlocation,
    device: this.state.device,
    isp: this.state.UserIsp,
    form_length: this.state.formlength,
    landed_on_page: this.state.pathname,
   });



  // console.log("fectc",res)

 
  }






handlePlaySecond = async () => {
  await this.setState({ 
   playingSecond: true }
    )
  console.log("handlePlaySecond",this.state.playingSecond)
}


  handlePause = async (state) => {
    await this.setState
     ({ playing: false })
    let paused = state.target.currentTime
    if (paused > 0) {
      let hours = Math.floor(paused / 3600);
      paused %= 3600;
      let mins = Math.floor(paused / 60);
      paused %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((paused < 10) ? "0" : "") + paused;
      VideoOnPlayPused.push(VideoOnPausedFormat)
      await this.setState({
        storepused: VideoOnPlayPused
      })
    }
  }
  handlePausesecond = async (state) => {
    await this.setState

      ({ playingSecond: false })
    let paused = state.target.currentTime
    if (paused > 0) {
      let hours = Math.floor(paused / 3600);
      paused %= 3600;
      let mins = Math.floor(paused / 60);
      paused %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((paused < 10) ? "0" : "") + paused;
      VideoOnPlayPusedSecond.push(VideoOnPausedFormat)
      await this.setState({
        storepusedSecond: VideoOnPlayPusedSecond
      })
      console.log("handlePausesecond",this.state.storepusedSecond)
    }
  }
  OnBuffer = async (e) => {

    let buffer = e.target.currentTime
    if (buffer > 0) {
      let hours = Math.floor(buffer / 3600);
      buffer %= 3600;
      let mins = Math.floor(buffer / 60);
      buffer %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((buffer < 10) ? "0" : "") + buffer;

      VideoBuffer.push(VideoOnPausedFormat)

      await this.setState({
        storeBuffer: VideoBuffer
      })}
  }
  OnBufferSecond = async (e) => {

    let buffer = e.target.currentTime
    if (buffer > 0) {
      let hours = Math.floor(buffer / 3600);
      buffer %= 3600;
      let mins = Math.floor(buffer / 60);
      buffer %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((buffer < 10) ? "0" : "") + buffer;

      VideoBufferSecond.push(VideoOnPausedFormat)

      await this.setState({
        storeBufferSecond: VideoBufferSecond
      })}
      console.log("storeBufferSecond",this.state.storeBufferSecond)

    }



  handleProgress = async (statse) => {

    let Progress = statse.playedSeconds
    if (Progress > 0) {
      let hours = Math.floor(Progress / 3600);
      Progress %= 3600;
      let mins = Math.floor(Progress / 60);
      Progress %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((Progress < 10) ? "0" : "") + Progress;
      await this.setState({
        storeprogress: VideoOnPausedFormat
      })
    }
  }

  handleProgressSecond = async (statse) => {
     let Progress = statse.playedSeconds
    if (Progress > 0) {
      let hours = Math.floor(Progress / 3600);
      Progress %= 3600;
      let mins = Math.floor(Progress / 60);
      Progress %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((Progress < 10) ? "0" : "") + Progress;
       await this.setState({
        storeprogressSecond: VideoOnPausedFormat
      })
console.log("storeprogressSecond",this.state.storeprogressSecond)
    }
  }


  OnseekFun = async (e) => {
this.setState({
  seekvideo:true,
})
    let seek = e
    if (seek > 0) {
      let hours = Math.floor(seek / 3600);
      seek %= 3600;
      let mins = Math.floor(seek / 60);
      seek %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((seek < 10) ? "0" : "") + seek;
      if (this.state.playing === false) {
        VideoSeek.push(VideoOnPausedFormat)

        await this.setState({
          storeseek: VideoSeek

        })
      }
    }
  }
  
  OnseekFunSecond = async (e) => {
    let seek = e
    if (seek > 0) {
      let hours = Math.floor(seek / 3600);
      seek %= 3600;
      let mins = Math.floor(seek / 60);
      seek %= 60;
      let VideoOnPausedFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((seek < 10) ? "0" : "") + seek;
      if (this.state.playing === false) {
        VideoSeekSecond.push(VideoOnPausedFormat)

        await this.setState({
          storeseekSecond: VideoSeekSecond
        })
      }
      // console.log("User_Started_Play_video_At",StoreVideoOnPlayFormat)
       console.log("storeseekSecond",this.state.storeseekSecond)

    }
  }



Emailsubmitedata= async () => {

  let seek={
  name:"User_skip_video",
  value:this.state.storeseek,
  date: this.state.UDate,
  timestamp: this.state.timestamp 
         }

    let videplytill={
        name:"Langing_page_Video_played_Till",
        value:this.state.storeprogress,
        date: this.state.UDate,
        timestamp: this.state.timestamp,
        status: this.state.status,
      }
      let pay={
        name:"Langing_page_Video_played",
        value:this.state.playing,
        date: this.state.UDate,
        timestamp: this.state.timestamp,
        status: this.state.status,
      }
        let pused={
      name:"Langing_page_Video_Paused",
      value:this.state.storepused,
      date: this.state.UDate,
      timestamp: this.state.timestamp,
      status: this.state.status,
    }
    let buffer={
      name:"Langing_page_Video_Buffered_Time",
      value:this.state.storeBuffer,
      date: this.state.UDate,
      timestamp: this.state.timestamp,
      status: this.state.status,
    }

    
    await this.setState({
      
      status:"Capture"
    
    });
  
      await this.setState({
      
        UserSelectedEmail: this.state.UserSelectedEmail.concat([pay])
      
      });
      
  
      await this.setState({

        UserSelectedEmail: this.state.UserSelectedEmail.concat([pused])
      
      });
      await this.setState({

        UserSelectedEmail: this.state.UserSelectedEmail.concat([buffer])
      
      });

      await this.setState({
      
        UserSelectedEmail: this.state.UserSelectedEmail.concat([videplytill])
      
      });
      await this.setState({
      
        UserSelectedEmail: this.state.UserSelectedEmail.concat([seek])
      
      });
      let affiliate_id = localStorage.getItem("affiliate_id");
      let user_id = this.state.UserID;
       localStorage.setItem("user_id", user_id)


      let res =await axios.post('https://comms.globalxchange.com/campaign/leadData',  {
        user_id:user_id,
        affiliate_id:affiliate_id,
        name:this.state.name,
        email:this.state.email,
        status: this.state.status,
        data: this.state.UserSelectedEmail,
        form_data: [
          {
            name: "gxc1campaignform1",
            date: this.state.UDate,
            timestamp: this.state.timestamp,
            status: this.state.status,
            fields: [
              {
               name:"Name",
               value:this.state.name,
             },
             {
               name: "Email",
               value: this.state.email
             }
            ]
          }
        ]
      })

    

     console.log("EmailData",res);
     res=""
 }

readyfun=()=>
{
  this.setState(
    {
      status:"educated" 
    }
  )
}



 Leavesubmitedata= async () => {
  let Compaigname = {
    name: "Campaign_Site_Name",
    value: this.state.campaignname,
  }
  let dates={
    name:"Date",
    value:this.state.UDate
  }
  let timstap={
    name:"TimeStamp",
    value:this.state.timestamp
  }
  let Ipaddress = {
    name: "User_Ip_Address",
    value: this.state.UserIpAddress,
  }
  let locaion = {
    name: "User_Location",
    value: this.state.Userlocation,
  }
  let FromLen = {
    name: "Form_Length",
    value: this.state.formlength,
  }
  let pagepath = {
    name: "User_Page_Viewed",
    value: this.state.pathname,
  }
  let ip={
    name:"User_ISP",
    value:this.state.UserIsp
  }
  let device={
    name:"User_Device",
    value:this.state.device
  }
  let status={
    name:"User_State",
    value:this.state.status
  }
  let seek={
    name:"User_Sikped_video",
    value:this.state.storeseek,
    date: this.state.UDate,
    timestamp: this.state.timestamp 
          }
 
    let videplytill={
         name:"Langing_page_Video_played_Till",
         value:this.state.storeprogress,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
       let pay={
         name:"Langing_page_Video_played",
         value:this.state.playing,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
         let pused={
       name:"Langing_page_Video_Paused",
       value:this.state.storepused,
       date: this.state.UDate,
       timestamp: this.state.timestamp,
       status: this.state.status,
     }
     let buffer={
       name:"Langing_page_Video_Buffered_Time",
       value:this.state.storeBuffer,
       date: this.state.UDate,
       timestamp: this.state.timestamp,
       status: this.state.status,
     }
    // 
    let seekSecond={
      name:"User_Skip_video_On_Fp2",
      value:this.state.storeseekSecond,
      date: this.state.UDate,
      timestamp: this.state.timestamp 
            }
   
      let videplytillSecond={
           name:"Video_played_Till_On_Fp2",
           value:this.state.storeprogressSecond,
           date: this.state.UDate,
           timestamp: this.state.timestamp,
           status: this.state.status,
         }
         let paySeconds={
           name:"Video_played_On_Fp2",
           value:this.state.playingSecond,
           date: this.state.UDate,
           timestamp: this.state.timestamp,
           status: this.state.status,
         }
           let pusedSecond={
         name:"Video_Paused_On_Fp2",
         value:this.state.storepusedSecond,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
       let bufferSecond={
         name:"Video_Buffered_Time_On_Fp2",
         value:this.state.storeBufferSecond,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
    // 
     if (this.state.name.length > 1) {
 
       let usnanety={
         name:"User_Type_on_Name_InputBox",
         value:this.state.usernameType,
       }
        await this.setState(
          {
           
            UserSelected: this.state.UserSelected.concat([usnanety])
          }
        )
        }
  
      if (this.state.email.length > 1) {
        let emailtype={
          name:"User_Type_on_Email_InputBox",
          value:this.state.useremailType,
        }
        await this.setState(
          {
             UserSelected: this.state.UserSelected.concat([emailtype])
          }
        )
      }

    await this.setState({
    UserSelected: this.state.UserSelected.concat([Compaigname])
    });
    await this.setState({
   UserSelected: this.state.UserSelected.concat([dates])
   });
   await this.setState({
   UserSelected: this.state.UserSelected.concat([timstap])
   });
   await this.setState({

    UserSelected: this.state.UserSelected.concat([Ipaddress])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([locaion])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([FromLen])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([pagepath])

  });
   await this.setState({

    UserSelected: this.state.UserSelected.concat([ip])

  });
    await this.setState({

    UserSelected: this.state.UserSelected.concat([device])

  });
    await this.setState({

    UserSelected: this.state.UserSelected.concat([status])

  });

 
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([pay])
      
      });
      
  
      await this.setState({

        UserSelected: this.state.UserSelected.concat([pused])
      
      });
      await this.setState({

        UserSelected: this.state.UserSelected.concat([buffer])
      
      });

      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([videplytill])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([seek])
      
      });

      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([seekSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([videplytillSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([paySeconds])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([pusedSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([bufferSecond])
      
      });

      let affiliate_id = localStorage.getItem("affiliate_id");
      let user_id = this.state.UserID;
       localStorage.setItem("user_id", user_id)

      let res =await axios.post('https://comms.globalxchange.com/campaign/leadData', {
        user_id:user_id,
        affiliate_id:affiliate_id,
        status: this.state.status,
        data: this.state.UserSelected,
        
      })
      console.log("leaveDatalol",this.state.UserSelected);
     await this.setState({
        UserSelected:[]

      })
      console.log("last",this.state.UserSelected);
 }







FinalLeavesubmitedata= async () => {
  let Compaigname = {
    name: "Campaign_Site_Name",
    value: this.state.campaignname,
  }
  let dates={
    name:"Date",
    value:this.state.UDate
  }
  let timstap={
    name:"TimeStamp",
    value:this.state.timestamp
  }
  let Ipaddress = {
    name: "User_Ip_Address",
    value: this.state.UserIpAddress,
  }
  let locaion = {
    name: "User_Location",
    value: this.state.Userlocation,
  }
  let FromLen = {
    name: "Form_Length",
    value: this.state.formlength,
  }
  let pagepath = {
    name: "User_Page_Viewed",
    value: this.state.pathname,
  }
  let ip={
    name:"User_ISP",
    value:this.state.UserIsp
  }
  let device={
    name:"User_Device",
    value:this.state.device
  }
  let status={
    name:"User_State",
    value:this.state.status
  }
  let seek={
    name:"User_Sikped_video",
    value:this.state.storeseek,
    date: this.state.UDate,
    timestamp: this.state.timestamp 
          }
 
    let videplytill={
         name:"Langing_page_Video_played_Till",
         value:this.state.storeprogress,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
       let pay={
         name:"Langing_page_Video_played",
         value:this.state.playing,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
         let pused={
       name:"Langing_page_Video_Paused",
       value:this.state.storepused,
       date: this.state.UDate,
       timestamp: this.state.timestamp,
       status: this.state.status,
     }
     let buffer={
       name:"Langing_page_Video_Buffered_Time",
       value:this.state.storeBuffer,
       date: this.state.UDate,
       timestamp: this.state.timestamp,
       status: this.state.status,
     }
    // 
    let seekSecond={
      name:"User_Skip_video_On_Fp2",
      value:this.state.storeseekSecond,
      date: this.state.UDate,
      timestamp: this.state.timestamp 
            }
   
      let videplytillSecond={
           name:"Video_played_Till_On_Fp2",
           value:this.state.storeprogressSecond,
           date: this.state.UDate,
           timestamp: this.state.timestamp,
           status: this.state.status,
         }
         let paySeconds={
           name:"Video_played_On_Fp2",
           value:this.state.playingSecond,
           date: this.state.UDate,
           timestamp: this.state.timestamp,
           status: this.state.status,
         }
           let pusedSecond={
         name:"Video_Paused_On_Fp2",
         value:this.state.storepusedSecond,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
       let bufferSecond={
         name:"Video_Buffered_Time_On_Fp2",
         value:this.state.storeBufferSecond,
         date: this.state.UDate,
         timestamp: this.state.timestamp,
         status: this.state.status,
       }
    // 
     if (this.state.name.length > 1) {
 
       let usnanety={
         name:"User_Type_on_Name_InputBox",
         value:this.state.usernameType,
       }
        await this.setState(
          {
           
            UserSelected: this.state.UserSelected.concat([usnanety])
          }
        )
        }
  
      if (this.state.email.length > 1) {
        let emailtype={
          name:"User_Type_on_Email_InputBox",
          value:this.state.useremailType,
        }
        await this.setState(
          {
             UserSelected: this.state.UserSelected.concat([emailtype])
          }
        )
      }

    await this.setState({
    UserSelected: this.state.UserSelected.concat([Compaigname])
    });
    await this.setState({
   UserSelected: this.state.UserSelected.concat([dates])
   });
   await this.setState({
   UserSelected: this.state.UserSelected.concat([timstap])
   });
   await this.setState({

    UserSelected: this.state.UserSelected.concat([Ipaddress])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([locaion])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([FromLen])

  });
  await this.setState({

    UserSelected: this.state.UserSelected.concat([pagepath])

  });
   await this.setState({

    UserSelected: this.state.UserSelected.concat([ip])

  });
    await this.setState({

    UserSelected: this.state.UserSelected.concat([device])

  });
    await this.setState({

    UserSelected: this.state.UserSelected.concat([status])

  });

 
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([pay])
      
      });
      
  
      await this.setState({

        UserSelected: this.state.UserSelected.concat([pused])
      
      });
      await this.setState({

        UserSelected: this.state.UserSelected.concat([buffer])
      
      });

      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([videplytill])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([seek])
      
      });

      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([seekSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([videplytillSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([paySeconds])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([pusedSecond])
      
      });
      await this.setState({
      
        UserSelected: this.state.UserSelected.concat([bufferSecond])
      
      });
      await this.setState({
      
        status:"clipped"
      
      });
      let affiliate_id = localStorage.getItem("affiliate_id");
      let user_id = this.state.UserID;
       localStorage.setItem("user_id", user_id)

      let res =await axios.post('https://comms.globalxchange.com/campaign/leadData', {
        user_id:user_id,
        affiliate_id:affiliate_id,
        status: this.state.status,
        data: this.state.UserSelected,
        
      })
    await  this.setState({
      
        UserSelected:[]
        
      })
      console.log("leaveData",res);
 }









  // {
  //   name:"Campaign_Site_Name",
  //   value: this.state.campaignname,
  // }, 
  // {
  //   name:"User_Name",
  //   value:username ,
  // },
  // {
  //   name:"Affiliate_Id_Of_Broker",
  //   value:affiliate_id,

  // },
  // {
  //    name: "User_Page_Viewed",
  //    value: this.state.pathname,
  // },
  // {
  //   name:"Page_Time",
  //   value:this.state.TodayPageTime,
  // },
  // {
  // name:"Page_Date",
  // value:this.state.TodayPagedate,
  // },
  // {
  // name:"User_location",
  // value:this.state.Userlocation,
  // },
  EmailSendcall = async () => {


  }


  ActionButton1 = async () => {
    await ({
      Buttonclick1: "Secure Your Free Account",
      status:"Clipped"
    })

   

  }


  ActionButton2 = async () => {
    await ({
      Buttonclick1: "Talk To A Crypto-Consultant",
      status:"Clipped"
    })
  }
  ActionButton3 = async () => {
    await ({
      Buttonclick1: "Book A Meeting",
      status:"Clipped",
    })
  }
  EmailFunction = () => {

    this.setState(
      {
        usernameEmailInput: ": Click"
      }
    )

  }
  NameFunction = async () => {
    await this.setState(
      {
        userNameInput: "Click"
      }

    )
  }



 

  VideoOnPlay = async (event) => {

    // console.log(player.currentTime);
    //  let sd=event.target.getCurrentTime()

    let videoPlayedAt = event.target.getCurrentTime()

    if (videoPlayedAt > 0) {
      let hours = Math.floor(videoPlayedAt / 3600);
      videoPlayedAt %= 3600;
      let mins = Math.floor(videoPlayedAt / 60);
      videoPlayedAt %= 60;
      let VideoOnPlayFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((videoPlayedAt < 10) ? "0" : "") + videoPlayedAt;
      StoreVideoOnPlayFormat.push(VideoOnPlayFormat)
      await this.setState({
        storevideplayed: StoreVideoOnPlayFormat
      })
      // console.log("User_Started_Play_video_At",StoreVideoOnPlayFormat)
      // console.log("Use",StoreVideoOnPlayFormat)
    }

  }
  VideoonPause = async (event) => {


    let videoPlayedPused = event.target.getCurrentTime()
    if (videoPlayedPused > 0) {
      let hours = Math.floor(videoPlayedPused / 3600);
      videoPlayedPused %= 3600;
      let mins = Math.floor(videoPlayedPused / 60);
      videoPlayedPused %= 60;
      let VideoOnPlayFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((videoPlayedPused < 10) ? "0" : "") + videoPlayedPused;
      StoreVideoOnPlayPused.push(VideoOnPlayFormat)
      await this.setState({
        storeVidePused: StoreVideoOnPlayPused
      })
      // console.log("User_Pused_video_At",StoreVideoOnPlayPused)
      // console.log("Use",StoreVideoOnPlayFormat)
    }

  }

  VideoOnPlayPageTwo = async (event) => {

    //  let sd=event.target.getCurrentTime()
    //  console.log("kamald",sd)   
    let videoPlayedAt = event.target.getCurrentTime()

    if (videoPlayedAt > 0) {
      let hours = Math.floor(videoPlayedAt / 3600);
      videoPlayedAt %= 3600;
      let mins = Math.floor(videoPlayedAt / 60);
      videoPlayedAt %= 60;
      let VideoOnPlayFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((videoPlayedAt < 10) ? "0" : "") + videoPlayedAt;
      StoreVideoOnPlayFormatTwo.push(VideoOnPlayFormat)
      await this.setState({
        storevideplayedTwo: StoreVideoOnPlayFormatTwo
      })
      // console.log("User_Started_Play_video_At",StoreVideoOnPlayFormat)
      // console.log("Use",StoreVideoOnPlayFormat)
    }

  }

  VideoonPausePageTwo = async (event) => {


    let videoPlayedPused = event.target.getCurrentTime()
    if (videoPlayedPused > 0) {
      let hours = Math.floor(videoPlayedPused / 3600);
      videoPlayedPused %= 3600;
      let mins = Math.floor(videoPlayedPused / 60);
      videoPlayedPused %= 60;
      let VideoOnPlayFormat = ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((videoPlayedPused < 10) ? "0" : "") + videoPlayedPused;
      StoreVideoOnPlayPusedTwo.push(VideoOnPlayFormat)
      await this.setState({
        storeVidePusedTwo: StoreVideoOnPlayPusedTwo
      })
      // console.log("User_Pused_video_At",StoreVideoOnPlayPused)
      // console.log("Use",StoreVideoOnPlayFormat)
    }

  }

  // VideoOnPlay= async(event)=> {
  //   let videoPlayedAt=event.target.getCurrentTime()

  //   var hours = new Date().getHours();
  //   var hourss = (hours+24-2)%24; 
  //   var mid='am';
  //   if(hourss===0){ 
  //   hours=12;
  //   }
  //   else if(hourss>12)
  //   {
  //   hours=hours%12;
  //   mid='pm';
  //   }
  //   let VideoOnPlayToday = new Date();
  //   var date = VideoOnPlayToday.getFullYear()+'-'+(VideoOnPlayToday.getMonth()+1)+'-'+VideoOnPlayToday.getDate();
  //   let time = VideoOnPlayToday.getHours() + ":" + VideoOnPlayToday.getMinutes() + ":" + VideoOnPlayToday.getSeconds();
  //   let TodayTime = time + mid;
  //  TotalTimeUser.push(TodayTime)
  //       if (videoPlayedAt > 0) {
  //       let hours= Math.floor(videoPlayedAt/3600);
  //         videoPlayedAt %= 3600;
  //         let mins = Math.floor(videoPlayedAt/60);
  //          videoPlayedAt %= 60;
  //         let  VideoOnPlayFormat = ( (hours < 10 ) ? "0" : "" ) + hours + ":" + ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (videoPlayedAt < 10) ? "0" : "" ) + videoPlayedAt;
  //       StoreVideoOnPlayFormat.push(VideoOnPlayFormat)

  //         console.log("User_Started_Play_video_At",StoreVideoOnPlayFormat)

  //         }
  //         await this.setState({
  //           storetotalTime:TotalTimeUser
  //         })
  //         console.log("Time_AT_User_played_video",this.state.storetotalTime)

  //   }












  dataTodayTwo = async () => {
    var hours = new Date().getHours();
    var hourss = (hours + 24 - 2) % 24;
    var mid = 'am';
    if (hourss === 0) {
      hours = 12;
    }
    else if (hourss > 12) {
      hours = hours % 12;
      mid = 'pm';
    }
    let VideoOnPlayToday = new Date();
    var date = VideoOnPlayToday.getFullYear() + '-' + (VideoOnPlayToday.getMonth() + 1) + '-' + VideoOnPlayToday.getDate();
    let time = VideoOnPlayToday.getHours() + ":" + VideoOnPlayToday.getMinutes() + ":" + VideoOnPlayToday.getSeconds();
    let TodayTime = time + mid;
    await this.setState({
      TodayPageTimeTwo: TodayTime,
      TodayPagedateTwo: date,


    })

    let ptime= {
      name: "Time_and_Date_Video_played ",
      value: this.state.TodayPageTimeTwo +'-'+ this.state.TodayPagedateTwo ,
    }
    // let pdate = {
    //   name: "video start",
    //   value: ,
    // }
    await this.setState({

      UserSelected: this.state.UserSelected.concat([ptime])

    });
    // await this.setState({

    //   UserSelected: this.state.UserSelected.concat([pdate])

    // });
    console.log(this.state.TodayPageTimeTwo)


  }


















  dataToday = async () => {
    var hours = new Date().getHours();
    var hourss = (hours + 24 - 2) % 24;
    var mid = 'am';
    if (hourss === 0) {
      hours = 12;
    }
    else if (hourss > 12) {
      hours = hours % 12;
      mid = 'pm';
    }
    let VideoOnPlayToday = new Date();
    var date = VideoOnPlayToday.getFullYear() + '-' + (VideoOnPlayToday.getMonth() + 1) + '-' + VideoOnPlayToday.getDate();
    let time = VideoOnPlayToday.getHours() + ":" + VideoOnPlayToday.getMinutes() + ":" + VideoOnPlayToday.getSeconds();
    let TodayTime = time + mid;
    await this.setState({
      TodayPageTime: TodayTime,
      TodayPagedate: date,


    })

  }





  // 
  updatePathName = async (path) => {
    await infopath.push(path);
    await this.setState({

      pathname: infopath
    })

  }


  // main Page  user time count...

  // var ;
  // var ticker;


  // 
  tick = async () => {
    secs = temp;

    if (secs > 0) {
      temp++;
      let days = Math.floor(secs / 86400);
      secs %= 86400;
      let hours = Math.floor(secs / 3600);
      secs %= 3600;
      mins = Math.floor(secs / 60);
      secs %= 60;
      pretty = ((days < 10) ? "0" : "") + days + ":" + ((hours < 10) ? "0" : "") + hours + ":" + ((mins < 10) ? "0" : "") + mins + ":" + ((secs < 10) ? "0" : "") + secs;
      // console.log(pretty) 
      // console.log("asdasd",secs)
      // console.log(mins)
    }


    if (this.state.stopPageOneTimmer === false) {

      await clearInterval(pageonetime);

      await this.setState(
        {

          UserStayedOnHomepageTime: pretty
        }
      )
    }

  }
  clearstop = () => {

    pageonetime = setInterval(this.tick, 20);


  }

  componentDidMount = async () => {
    // await this.clearstop()
    // await this.dataToday()


    // await this.locationaddressFetch()




  }

  detectMob = () => {

    if (sessionStorage.desktop) // desktop storage 
      return false;
    else if (localStorage.mobile) // mobile storage
      return true;

    // alternative
    var mobile = ['iphone', 'ipad', 'android', 'blackberry', 'nokia', 'opera mini', 'windows mobile', 'windows phone', 'iemobile'];
    for (var i in mobile) if (navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0)
      console.log("mobile",mobile[i])
    return true;

    // nothing found.. assume desktop
    // return false;
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  //     updateFunc =async (n1) => {
  //       let obj = {
  //         name:n1.name,
  //         value:n1.value
  //       }

  // await this.setState({

  //   UserSelected:this.state.UserSelected.concat([obj])
  // });
  //     }
  inputValue = async e => {

    let n1 = e.target


   

    await this.setState({
      [n1.name]: n1.value,

    });
    const email = this.state.email
    const emailValid = this.validateEmail(email)
    console.log("email,", email)
    await this.setState({
      valid: emailValid

    });

    if (!this.state.valid) {
      console.log(Error)
    }
    else {

      await this.setState({
        next: true

      });

    }
     if (this.state.name.length > 1) {

      
       await this.setState(
         {
           usernameType:true,
       
         }
       )
       }
 
     if (this.state.email.length > 1) {
     
       await this.setState(
         {
           useremailType:true,
         }
       )
     }

  

  }

  sendData = async () => {
  }





  handleShow = async () => {

    await this.setState({
      show: true,
      webinarbutton: "Click"
    })
    let buttobj = {
      name: "LandingPage_Webiner_Buttom",
      value: this.state.webinarbutton,
    }
    await this.setState({

      UserSelected: this.state.UserSelected.concat([buttobj]),
      UserSelectedEmail: this.state.UserSelectedEmail.concat([buttobj])
    });

  }

  handleClose = async () => {
  await  this.setState({
      show: false,

    })


  }

  render() {
    // console.log("kamalpused",this.state.storepused)
    // console.log('onProgress',this.state.storeprogress )
    // console.log(this.state.name,this.state.email)
    // console.log("UserStayedOnHomepageTime",this.state.UserStayedOnHomepageTime)
    // console.log("userpageName",this.state.pathname)
    // console.log("UserID",this.state.UserID)
    // console.log("user_not_HomePage_click_Button:",this.state.webinarbutton)
    // console.log("TodayPageTime:",this.state.TodayPageTime)
    // console.log("TodayPagedate:",this.state.TodayPagedate)
    // console.log("ipaddress",this.state.UserIpAddress)
    // console.log("userName_Name_input",this.state.userNameInput)
    // console.log("User_typed_inputBox", this.state.usernameType)
    // console.log("User_Email_input",this.state.usernameEmailInput)
    // console.log("User_type_Email",this.state.useremailType)
    // console.log("User close popup",this.state.popupClose)

   


    return (
      <>
        <ProductContext.Provider value={{
          ...this.state,
          OnBufferSecond:this.OnBufferSecond,
          handleProgressSecond :this.handleProgressSecond ,
          VideoOnPlay: this.VideoOnPlay,
          SendAllLandingData: this.SendAllLandingData,
          OnseekFun: this.OnseekFun,
          handlePlay: this.handlePlay,
          OnseekFunSecond:this.OnseekFunSecond,
          NameFunction: this.NameFunction,
          updatePathName: this.updatePathName,
          handleShow: this.handleShow,
          handleClose: this.handleClose,
          inputValue: this.inputValue,
          sendData: this.sendData,
          onPlaybackRateChangedata: this.onPlaybackRateChangedata,
          VideoonPause: this.VideoonPause,
          videoOnePlay: this.videoOnePlay,
          EmailFunction: this.EmailFunction,
          SendAllData: this.SendAllData,
          VideoOnPlayPageTwo: this.VideoOnPlayPageTwo,
          VideoonPausePageTwo: this.VideoonPausePageTwo,
          dataTodayTwo: this.dataTodayTwo,
          ActionButton1: this.ActionButton1,
          ActionButton2: this.ActionButton2,
          ActionButton3: this.ActionButton3,
          handlePause: this.handlePause,
          handleProgress: this.handleProgress,
          onstartfun: this.onstartfun,
          OnBuffer: this.OnBuffer,
          EmailSendcall: this.EmailSendcall,
          submitedata:this.submitedata,
          Emailsubmitedata:this.Emailsubmitedata,
          Leavesubmitedata:this.Leavesubmitedata,
          handlePlaySecond:this.handlePlaySecond,
          handlePausesecond:this.handlePausesecond,
          FinalLeavesubmitedata:this.FinalLeavesubmitedata,
          readyfun:this.readyfun,
        }}>
          {this.props.children}
        </ProductContext.Provider>
      </>
    )
  }
}
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };