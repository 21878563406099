import React, { Component } from 'react'



import { ProductConsumer } from "../../ContextApi";

import { NavLink, Redirect } from "react-router-dom";
let s=''

export default class Fp3 extends Component {
 static contextType = ProductConsumer;

 state=
 {
  TodayPageTimeTwo:"",
  TodayPagedateTwo:""
 }
 componentDidMount=async()=>
 {
  await this.context.dataTodayTwo();
  await this.context.updatePathName(window.location.pathname + window.location.search);
  window.onbeforeunload = async (evt)=> {
    await this.context.Leavesubmitedata()
 
    var message = 'Are you sure you want toleave?';

    if (typeof evt == 'undefined') {
     evt.returnValue =" message"
    }       
    if (evt) {     
    evt.returnValue =" message";
    }
    console.log("windiwmessge",evt)
     return message;
  
  
}

window.onunload = async()=> {

  await this.context.Leavesubmitedata()


}

 }


  render() {
    const opts = {
      // height: '390',
      // width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: false,
        controls:0,
      
        loop:0
      }
    };
    return (
      <ProductConsumer>
        {context_data => {
          const {
            affiliate_id,
      
            FinalLeavesubmitedata,
         
            
          } = context_data;

      
          return (
            <>
            <div className="MainHomesection">
            <div className="container">
                <div className="homesecfp">
                <div className="subsechome">
 <div class="container  d-flex justify-content-center p-0">
                <div class="row">
              <div class="col-md-4 ">
              <a
href={`https://gxcheckout.com/${localStorage.getItem(
  affiliate_id
)}`}
target="_blank" onClick={FinalLeavesubmitedata}
>
             <button type="button" class="btn btn-primary btn-clas">Secure Your Free Account
                </button>
              </a>
            </div>
            <div class="col-md-4 ">
              <a href="https://api.whatsapp.com/send?phone=16477234329&text=&source=&data=" target="_blank"> <button type="button" class="btn btn-primary btn-clas" onClick={FinalLeavesubmitedata}>Talk To A Crypto-Consultant 
              </button>
            </a>
          </div>
          <div class="col-md-4 ">
           <a href="https://gxbroker.com/" target="_blank"> <button type="button" class="btn btn-primary btn-clas" onClick={FinalLeavesubmitedata} >Book A Meeting 
              </button></a> 
          
          </div>
        </div>
        </div>
            </div>
 
            </div>
            </div>



          

      
      </div>
            </>
               
          );
        }}
      </ProductConsumer>
    );
  }
}



